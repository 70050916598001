// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
// required for action cable, but it's not in use right now
//import "channels"

Rails.start()
ActiveStorage.start()

import 'src/digitalwerk/style';
import 'src/additional.scss';

// To copy text to clipboard (e.g. users access token)
import ClipboardJS from 'clipboard';

// Needed for Bootstrap Dropdowns
require("popper.js");

// short-hand for '$(document).ready(function() {'
$(()=>{

  // Initialize bootstrap based tooltips
  $('[data-toggle="tooltip"]').tooltip();
  // initialize bootstrap popovers
  $('[data-toggle="popover"]').popover();
  //
  $('.dropdown-toggle').dropdown();

  // change text and icon of collapse button for features, feature constraints and contacts in procuts#show and license#show view
  $(".toggle-button").on("click", function(event) {
      var show = $(this).attr("data-title");
      var hide = $(this).attr("data-hide");

    if ($(this).attr("data-original-title") === "" || $(this).attr("data-original-title") === show) {
      $(this).attr("data-original-title", hide).tooltip("hide").tooltip("show");
      $(this).children().toggleClass("bi-arrows-expand bi-arrows-collapse");
    } else {
      $(this).attr("data-original-title", show).tooltip("hide").tooltip("show");
      $(this).children().toggleClass("bi-arrows-collapse bi-arrows-expand");
    }
  });

  // initialiaze clipboard
  var clipboard = new ClipboardJS('.clipboard-btn');

  // define success tooltip event
  clipboard.on('success', function(e) {
    $(e.trigger).attr('data-original-title', 'Copied').tooltip('show');
  });

  // define error tooltip event
  clipboard.on('error', function(e) {
    $(e.trigger).attr('data-original-title', 'Error!').tooltip('show');
  });
});
